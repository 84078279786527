import React from 'react'
import { Link } from "react-router-dom";
import Iconfiy from 'pages/Utility/Iconfiy'
import PropTypes from "prop-types";


const BackComponent = ({link, title, device}) => {
 return (
    <>
        <Link to={link}   className={device === "mobile" ? '' : 'mobileBack'}>
            <div className='close-button align-items-center' style={{ justifyContent: "flex-start", marginBottom: '20px' }}>
                <Iconfiy icon="ep:arrow-left" width="15px"/> <h5 style={{ margin: '0px', marginLeft: '10px', fontSize: '20px' }}>{title}</h5>
            </div>
       </Link> 
    </>
 )
}


BackComponent.propTypes = {
    link: PropTypes.any,
    title: PropTypes.any,
    device: PropTypes.any,
  };

export default  BackComponent

  

  
