import React, { useState, useEffect } from 'react'
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card } from "reactstrap"
import DashSide from '../Dashboard/DashSide'
import Iconfiy from 'pages/Utility/Iconfiy'
import ProfileCom from "../../components/Common/ProfileCom"
import GeneralCard from "../../components/Common/GeneralCard"
import axios from "axios";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});


const Profile = () => {

    const [userBanks, setUserBanks] = useState([])
    const [balance, setBalance] = useState(0)


  const getUserWallet = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
      setBalance(res.data.balance)
    }).catch((error) => {
     console.log(error)
    })
  }
  
  const getUserBanks = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
      setUserBanks(res.data.data)
    }).catch((error) => {
  
    })
  }


  useEffect(() => {
    getUserWallet()
    getUserBanks()
  }, [balance])


    const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>SpooPay | My Profile</title>
                </MetaTags>

                    <Container>
                        <Row>
                        <Col md="10">
                            <Row>
                                <Col md="5">
                                <Card style={{ borderRadius: "10px", backgroundColor: "white"}}>
                                    <DashSide balance={balance} userBanks={userBanks}/>
                                </Card>
                                </Col>

                                <Col md="6">
                                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "239px"}}>
                                    <h2>My Profile</h2>
                                    </div>
                                     <hr />
                                     <ProfileCom user={user} />

                                     <br />

                                    <div className='plan-head'>
                                      <h4 style={{ marginBottom: '20px' }}>Setting</h4>
                                    </div>

                                    {/* <GeneralCard title="Change Password" details="Security threat? Change password" />
                                    <GeneralCard title="Support" details="Reach us for any help" />
                                    <GeneralCard title="Payout Banks" details="Your payout accounts" />
                                    <GeneralCard title="Change Transaction Pin" details="Pin compromise? Change now" />
                                    <GeneralCard title="Delete Account" details="" /> */}
                                    <GeneralCard title="Logout" details="" />

                                </Col>
                            </Row>
                        </Col>
                        </Row>
                    </Container>
            </div>
        </React.Fragment>
    )
}
export default  Profile
