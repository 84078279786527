import React, { useRef, useState } from 'react'
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags';
import { Container } from 'reactstrap';
import Notifications from 'pages/Utility/Notifications';
import axios from "axios";
import Loader from 'pages/Utility/Loader'
import AuthCode from "react-auth-code-input"


const API_URL = process.env.REACT_APP_BASE_URL;


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});





const ConfimrPin = props => {
  const [pin, setPin] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, seterrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleChange = (code) => {
    setPin(code)
  }

  const savePin = () => {
       let data = {
        pin: pin
       }
      setLoading(true)
      axiosApi.post(`${API_URL}/api/v1/user/pin/set`, data).then((res) => {
      setLoading(false)
      setSuccessMessage("Pin created sucesfully")
      props.history.push("/save-bank")
    }).catch((error) => {
      setLoading(false)
      seterrorMessage(error.response.message)
    })
  }



  return (
    <React.Fragment>
      <div className='all-container'>
        <MetaTags>
          <title>SpooPay | Create Transaction Pin</title>
        </MetaTags>

   
        <Container>
        {loading && <Loader/>}
          <div className='container-fluid d-flex justify-content-center pb-2'>
            <div className='major-width continue'>
              <div className='text-center migi'>
                <h2>Create your transaction PIN</h2>
                <p>Create a 4 digits unique PIN</p>
              </div>

              <div className='verification-container otps' style={{ marginBottom: '70px' }}>
              <AuthCode
                characters={4}
                className="form-control form-control-lg text-center"
                inputStyle={{
                  width: "50px",
                  height: "calc(1.5em + 1rem + 2px)",
                  padding: ".5rem 1rem",
                  borderRadius: "8px",
                  fontSize: "1.01562rem",
                  textAlign: "center",
                  marginRight: "15px",
                  border: "1px solid #ced4da",
                  textTransform: "uppercase",
                  borderRadius: ".4rem"
                }}
                onChange={handleChange}
              />
              </div>

              <button className='btn btn-primary full' style={{ marginTop: "30px"}} onClick={() => {savePin()}}>Continue</button>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfimrPin

ConfimrPin.propTypes = {
  history: PropTypes.object,
};
