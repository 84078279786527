import React, { useEffect, useState } from 'react'
import Iconfiy from 'pages/Utility/Iconfiy'
import { MetaTags } from 'react-meta-tags'
import { Container, Alert } from 'reactstrap'
import axios from "axios";
import Loader from 'pages/Utility/Loader'
import PropTypes from "prop-types";
import Iconify from '../Utility/Iconfiy'
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";



const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});




const SaveBankDetails = props => {
  const [banks, setBanks] = useState([])
  const [loading, setLoading] = useState(false)
  const [bankData, setBankData] = useState({ bank_name: "", account_number: "", bank_code: "", account_name: ""})
  const [account_name, setAccountName] = useState("")
  const [isOpen, setIsOpen] = React.useState(true);
  const [disabled, setDisabled] = useState(false)
  const selectRef = React.useRef(null);
  const [count, setCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const saveBank = async () => {
        let bank_data = {
          bank_name: bankData.bank_name,
          account_name : account_name,
          code: bankData.bank_code,
          account_number: bankData.account_number
        }
  
        setLoading(true)
        await axiosApi.post(`${API_URL}/api/v1/user/bank/add`, bank_data).then((res) => {
        setSuccessMessage("Bank details saved succesfully")
        setLoading(false)
        props.history.push("/dashboard")
     }).catch((error) => {
        setErrorMessage(error.response.data.message)
        setLoading(false)
     })
  }


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (e) => {
    setIsOpen(false);
    let newData = e.target.value.split(",")
    setBankData({...bankData, bank_code: newData[0], bank_name: newData[1]})
  };

  const handleSelectClick = () => {
    toggleDropdown()
    if (selectRef.current) {
      selectRef.current.click();
    }
  };




  const getAccountDetails = () => {
    setDisabled(true)
    setLoading(true)
    let data = {
      account_number: bankData.account_number,
      bank_code: bankData.bank_code
    }
    axiosApi.post(`${API_URL}/api/v1/user/bank/verify`, data).then((res) => {
      setAccountName(res.data.data.account_name)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      setDisabled(true)
    })
  }

  const handleChange = (e) => {
      setBankData({...bankData, account_number: e.target.value})
      setCount(bankData.account_number.length)
  }


  useEffect(() => {
     axiosApi.get(`${API_URL}/api/v1/banks`).then((res) => {
       setBanks(res.data.data)
     })
  }, [])

  useEffect(() => {
    if(count > 9){
      getAccountDetails()
    }

  }, [count])



  return (
    <React.Fragment>
      <div className='all-container'>
        <MetaTags>
          <title>SpooPay | Add your bank account</title>
        </MetaTags>

        <Container>
         
         <div className='container-fluid d-flex justify-content-center'>
         {loading && <Loader/>}
            <div className='major-width'>
                <div className='close-button' style={{ justifyContent: "flex-start", marginBottom: '30px' }}>
                    <Iconfiy icon="ep:arrow-left" width="15px"/>
                  </div>
  
                  <div className='text-left migi'>
                    <h2>Save Bank For Withdrawals</h2>
                    <p>Your withdrawals will always be paid to these bank account</p>
                    {errorMessage ? <Alert color="danger">{errorMessage}</Alert>  : null}
                  </div>
  
                  <div className='input-set'>
                    <label className='form-label'>Bank</label>
                      <div id='custom-select' className={`form-control mine-input ${isOpen ? '' : 'open'}`} onClick={handleSelectClick}>
                        <select ref={selectRef} onChange={(e) => {handleOptionSelect(e)}}>
                          <option value="" selected disabled>-- Select Bank --</option>
                          {banks?.map((bank) => (
                             <>
                                 <option value={[bank.code, bank.name]}>{bank.name}</option>
                             </>
                           
                          ))}
                        </select>
                          <div className='customdrop-icon'>
                            <Iconfiy icon="ep:arrow-down"/>
                          </div>
                      </div>
                  </div>
  
                  <div className='input-set'>
                    <label className='form-label'>Account Number</label>
                    <input type='number' className='form-control mine-input' onKeyUp={() => setCount(count + 1)} onChange={handleChange} disabled={disabled}  placeholder='Enter your Account Number'/>
                  </div>
  
                  <div className='input-set'>
                    <label className='form-label'>Account Name</label>
                    <input type='text' value={account_name}  className='form-control mine-input' disabled/>
                  </div>

                  
  
                  <button className='btn btn-primary full mt-5' onClick={saveBank}>Save Bank</button>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SaveBankDetails


SaveBankDetails.propTypes = {
  history: PropTypes.object,
};
