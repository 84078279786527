import React from 'react'
import Iconfiy from 'pages/Utility/Iconfiy'
import PropTypes from 'prop-types';




const IndividualWallets = ({ bg, currency_symbol, amount, extra_text, wallet_name, textColor, country_icon, name_color, eye_color }) => {
  return (
    <React.Fragment>
      <div className='indiv-wallet' style={{ backgroundColor: bg}}>
        <div className='top-part d-flex justify-content-between'>
          <div className='d-flex' style={{ gap: "8px" }}>
            <img className='image' src={country_icon} width={40} alt='' />
            <div className='d-flex align-items-center'><span style={{ fontWeight: "bold", color: name_color}}> {wallet_name}</span></div>
          </div>
          <Iconfiy icon="iconamoon:menu-kebab-vertical-bold" width="24"/>
        </div>

        <div className='bottom d-flex flex-column'>
          <div className='d-flex justify-content-between align-items-center mb-2' style={{ width: '70%' }}>
            <h2 style={{ color: textColor}} ><span>{currency_symbol}</span>{amount}</h2>
            <Iconfiy color={eye_color} icon="ion:eye"/>
          </div>
          <p style={{ color: "gray"}}>{extra_text}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IndividualWallets

IndividualWallets.propTypes = {
  bg: PropTypes.any,
  currency_symbol: PropTypes.any,
  amount: PropTypes.any,
  wallet_name: PropTypes.any,
  name_color: PropTypes.any,
  textColor: PropTypes.any,
  country_icon: PropTypes.any,
  eye_color: PropTypes.any,
  extra_text: PropTypes.any
}