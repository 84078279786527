import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga";


import chatSaga from "./chat/saga"
import batchSaga from "./auth/batch/saga"
import adminSaga from "./admin/saga"
import transactionSaga from "./transactions/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(batchSaga),
    fork(adminSaga),
    fork(chatSaga),
    fork(transactionSaga)
  ])
}
