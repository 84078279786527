
// admins
export const GET_ADMINS = "GET_ADMINS"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL"

// close batch types
export const CLOSE_ADMIN_BATCH = "CLOSE_ADMIN_BATCH"
export const CLOSE_ADMIN_BATCH_SUCCESS = "CLOSE_ADMIN_BATCH_SUCCESS"
export const CLOSE_ADMIN_BATCH_FAIL = "CLOSE_ADMIN_BATCH_FAIL"


/**
 * add user
 */
 export const ADD_NEW_ADMIN = "ADD_NEW_ADMIN"
 export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS"
 export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL"

 export const DELETE_USER = "DELETE_USER"
 export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
 export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

 export const UPDATE_ADMIN = "UPDATE_ADMIN"
 export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS"
 export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL"

export const GET_LOGS = "GET_LOGS"
export const GET_LOG_SUCCESS = "GET_LOG_SUCCESS"
export const  GET_LOG_FAIL = "GET_LOG_FAIL"

 