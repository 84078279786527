import Iconfiy from 'pages/Utility/Iconfiy'
import React from 'react'
import { Container } from 'reactstrap'
import IndividualWallets from './IndividualWallets'
import ngnIcon from "assets/images/ngn.png"
import ghanaIcon from "assets/images/ghana.png"
import usaIcon from "assets/images/usa.png"


const WalletView = () => {
  return (
    <React.Fragment>
      <div className='d-flex flex-column wallet-view' style={{ gap: '20px' }}>
        <div className='d-flex justify-content-between align-items-center wallet-atn'>
          <div className='d-flex align-items-center' style={{ gap: '10px' }}>
            <button className='active'>add wallet</button>
          </div>
        </div>

        <div className="d-flex overflow-x-auto all-wallets no-scrollbar" style={{ gap: '20px' }}> 

          <IndividualWallets bg="#fff" country_icon={ngnIcon} currency_symbol="₦" wallet_name="NGN Wallet" name_color="black" textColor="black" amount="0.00" eye_color="black"/>
          <IndividualWallets bg="#000" country_icon={ghanaIcon} currency_symbol="&#162;" wallet_name="Ghana Wallet" textColor="white" amount="0.00" extra_text="Coming soon"/>
          <IndividualWallets bg="#000" country_icon={usaIcon} currency_symbol="&#36;" wallet_name="USD Wallet" textColor="white" amount="3.000" extra_text="Coming soon"/>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WalletView