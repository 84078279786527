import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import SpoowealthIcon from "../../assets/images/spoowealth.png"
import SpoosaveIcon from "../../assets/images/spoosafe.png"
import SpooFlexIcon from "../../assets/images/spooflex.png"
import SpooCircle from "../../assets/images/spoocircle.png"
import { Link } from "react-router-dom";



const Savings = ({title, details, id, link, isActive}) => {
  return (
    <Link to={link}>
    <div style={isActive  ? {border: '1px solid #6210CC'} : null} className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
         {id === 1 ? <img src={SpoowealthIcon}/> : id === 2 ?  <img src={SpoosaveIcon}/> : id === 3 ?  <img src={SpooFlexIcon}/> : id === 4 ? <img src={SpooCircle}/> :   null}
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{title}</h4>
        <p>{details}</p>
      </div>

      <Iconfiy icon="ep:arrow-right"/>
    </div>
    </Link>
  )
}

export default Savings


Savings.propTypes = {
  title: PropTypes.any,
  details: PropTypes.any,
  id: PropTypes.any,
  link: PropTypes.any,
  isActive: PropTypes.bool,
}


  