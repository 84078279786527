import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import { Link } from "react-router-dom";


const GeneralCard = ({title, details}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
         {
         title === "Logout" ? (<><Iconfiy icon="majesticons:logout"  color="orange"/></>) :
         title === "Delete Account" ? (<><Iconfiy icon="mingcute:delete-fill"  color="red"/></>) :
         title === "Change Transaction Pin" ? (<><Iconfiy icon="basil:edit-solid"  color="black"/></>) :
         title === "Payout Banks" ? (<><Iconfiy icon="mingcute:bank-fill"  color="black"/></>) :
         title === "Support" ? (<><Iconfiy icon="fluent:person-support-28-filled"  color="black"/></>) :
         title === "Change Password" ? (<><Iconfiy icon="mdi:password-add"  color="black"/></>) :
         null
         }
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{title}</h4>
        <p>{details}</p>
      </div>

      <Iconfiy icon="ep:arrow-right"/>
    </div>
  )
}

export default GeneralCard


GeneralCard.propTypes = {
  title: PropTypes.any,
  details: PropTypes.any,
  id: PropTypes.any,
  link: PropTypes.any
}


  