import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";

import NumberFormat from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar"
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody
} from "reactstrap";

import Savings from "../../components/Common/Savings"
import { Link, useParams } from "react-router-dom";
import Iconfiy from 'pages/Utility/Iconfiy'
import DashSide from '../Dashboard/DashSide'




const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});




const SavingsPage = props => {
    const [userBanks, setUserBanks] = useState([])
    const [balance, setBalance] = useState(0)
    const [transactions, setTransactions] = useState([])
    const [modal, setModal] = useState(false)
    const [transaction, setTransaction] = useState()
  
  
    const getUserWallet = async () => {
      axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
        setBalance(res.data.balance)
      }).catch((error) => {
       console.log(error)
      })
    }
    
    const getUserBanks = async () => {
      axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
        setUserBanks(res.data.data)
      }).catch((error) => {
    
      })
    }
  
    const getUserTransactions = () => {
      let limit = 5
      axiosApi.get(`${API_URL}/api/v1/user/transactions/${limit}`).then((res) => {
        setTransactions(res.data.data)
      }).catch((error) => {
    
      })
    }
    
    
    const toggle = () => {
      setShow(!show)
    }
    
    useEffect(() => {
      getUserWallet()
      getUserBanks()
      getUserTransactions()
    }, [balance])
  
    const transactionDetails = async (trans) => {
      setTransaction(trans)
      setModal(true)
  }
  
  const toggleTrans = () => {
    setModal(!modal)
  }
    
  
  
  
  

  return (
    <React.Fragment>
      
      <div className="page-content">
        <MetaTags>
          <title>Savings | Saving plan for everyone</title>
        </MetaTags>
        <Container fluid>
           <Row>

           <Col md="4">
               <DashSide balance={balance} userBanks={userBanks} />
           </Col>


                 <Col md="5">
                   <Link to="/dashboard">
                    <div className='close-button align-items-center' style={{ justifyContent: "flex-start", marginBottom: '20px' }}>
                      <Iconfiy icon="ep:arrow-left" width="15px"/> <h5 style={{ margin: '0px', marginLeft: '10px', fontSize: '20px' }}>Savings</h5>
                    </div>
                   </Link> 
                 <Savings id={1} title="Spoowealth" details="Earn upto 15% interest per month" isActive={true} link="/spoowealth"  />
                 {/* <Savings id={2} title="Spoosafe" details="Fixed deposit with 5% per month"  link="/spoosafe"  />
                 <Savings id={3} title="SpooFlex" details="Save and withdraw anytime 2.5%" link="/spooflex" /> */}
                 {/* <Savings id={4} title="SpooCircle" details="Save with your friends to reach a goal"  link="/spoocircle" /> */}
             </Col>
           </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

SavingsPage.propTypes = {
  t: PropTypes.any,
};

export default SavingsPage
