import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import Savings from "../../assets/images/blackIcons/savings.png"
import Airtime from "../../assets/images/blackIcons/airtime.png"
import Data from "../../assets/images/blackIcons/data.png"
import Cable from "../../assets/images/blackIcons/cable.png"
import Electricity from "../../assets/images/blackIcons/electricity.png"
import SendMoney from "../../assets/images/blackIcons/send.png"
import Salari from "../../assets/images/blackIcons/salary.png"
import { Link } from "react-router-dom";



const Services = ({title, details, id, link}) => {
  return (
    <Link to={link}>
    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
         {id === 1 ? <img src={Airtime}/> : id === 2 ?  <img src={Data}/> : id === 3 ?  <img src={Cable}/> : id === 4 ? <img src={Electricity}/> : id === 5 ? <img src={SendMoney}/> : id === 6 ? <img src={Savings}/> : id === 7 ? <img src={Salari}/> :     null}
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{title}</h4>
        <p>{details}</p>
      </div>

      <Iconfiy icon="ep:arrow-right"/>
    </div>
    </Link>
  )
}

export default Services


Services.propTypes = {
  title: PropTypes.any,
  details: PropTypes.any,
  id: PropTypes.any,
  link: PropTypes.any
}


  