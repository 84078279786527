import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const PlanCom = ({ startAmount, duration, status, link }) => {
  
  // const handleClick = (e) => {
  //   e.preventDefault();
  //   changeSingLink(linkTo) onClick={handleClick}
  // }

  return (
    <Link to={link}>
      <div className='individual-plans' >
        <div className='amount'>
          <p className='title start'>Starter</p>
          <p> &#8358;{startAmount} </p>
        </div>
        <div className='duration'>
          <p className='title'>Duration</p>
          <p>{duration} days</p>
        </div>
        <div className='status'>
          <p className='title'>Status</p>
          <p style={status === "ACTIVE" ? {color: "GREEN"} : null}>{status}</p>
        </div>

        <div className='status'>
          <p className='title'></p>
          <p className='btn btn-default'>View More</p>
        </div>
      </div>
    </Link>
   
  )
}

PlanCom.propTypes = {
  startAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  link: PropTypes.any
};

export default PlanCom


// &#8358;Nn &#x20A6; 
// IndivPlan.propTypes = {
//   startAmount: PropTypes.string.isRequired,
//   linkTo: PropTypes.string.isRequired,
//   status: PropTypes.string.isRequired,
//   duration: PropTypes.number.isRequired,
// };