import React, {useState} from 'react'
import { MetaTags } from 'react-meta-tags'
import Iconfiy from '../Utility/Iconfiy'
import { Container, Alert } from 'reactstrap'
import axios from "axios";
import Loader from 'pages/Utility/Loader'
const API_URL = process.env.REACT_APP_BASE_URL;
import PropTypes from "prop-types";

const axiosApi = axios.create({
  baseURL: API_URL,
});




const UserForgetPassword = props => {
  const [errorMessage, setErrorMessage] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const verifyMail = async () => {
    setLoading(true)
    let data = {
      email: email,
    }
    axiosApi.post(`${API_URL}/api/v1/user/password/send-otp`, data).then((res) => {
    setLoading(false)
    let data = {
      email: email
   }
   localStorage.setItem("token", JSON.stringify(data));
   props.history.push("/change-password");
    
    }).catch((error) => {
      setLoading(false);
      setErrorMessage(error.response.data.message);
    })
  }



  return (
    <React.Fragment>
      <div className='all-container'>
        <MetaTags>
          <title>Password Recovery | Save, Earn & Spend At Your Finger Tips</title>
        </MetaTags>

       

        <Container>
        {loading && <Loader/>}
          <div className='container-fluid d-flex justify-content-center'>
            <div className='major-width' style={{ paddingBottom: "30px", border: "1px solid gray" }}>
                  <>
                    <a href='/login'>
                    <div className='close-button' style={{ justifyContent: "flex-start", marginBottom: '30px' }}>
                      <Iconfiy icon="ep:arrow-left" width="15px"/>
                    </div>
                    </a>
                   

                    <div className='text-left migi'>
                      <h2>Forget password?</h2>
                      <p>Enter your email to start your recovery process.</p>
                      {errorMessage ? (<> <Alert color="danger">{errorMessage}</Alert> </>) : null}
                    </div>

                    <div className='input-set'>
                      <label className='form-label'>Email</label>
                      <input type='text' name="email" onChange={(e) => setEmail(e.target.value)} className='form-control mine-input' placeholder='eg. ub@gmail.com'/>
                    </div>

                    <button className='btn btn-primary full mt-5' onClick={() => {verifyMail() }}>Continue</button>
                  </> 
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserForgetPassword

UserForgetPassword.propTypes = {
  history: PropTypes.object,
};


// ep:arrow-left
