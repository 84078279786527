import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const Profilecom = ({ user }) => {


  return (
      <div className='individual-plans' >
        <div className='amount'>
          <p className='title start'>Fullname</p>
          <p>{user.data.first_name + " " + user.data.last_name} </p>
        </div>
        <div className='duration'>
          <p className='title'>Country</p>
          <p>{user.data.country}</p>
        </div>
        <div className='status'>
          <p className='title'>Email</p>
          <p>{user.data.email}</p>
        </div>

        <div className='status'>
          <p className='title'>Phone Number</p>
          <p>{user.data.phone_number}</p>
        </div>

        <div className='status'>
          <p className='title'>Gender</p>
          <p>{user.data.gender}</p>
        </div>

        <div className='status'>
          <p className='title'>Joined date</p>
          <p>{user.data.createdAt}</p>
        </div>

        <div className='status'>
          <p className='title'>Username</p>
          <p>{user.data.username}</p>
        </div>
      </div>
   
  )
}

Profilecom.propTypes = {
  user: PropTypes.any
};

export default Profilecom
