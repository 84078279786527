import React from 'react'
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';


const PlanCard = ({title, amount, onclick, btnText, disabled}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
       
      </div>
      <div style={{ flexGrow: "1" }}>
        <h4>{title}</h4>
        <p>
        <NumberFormat
            value={amount} 
            className="foo"
            displayType={'text'}
            thousandSeparator={true}
            renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
         /> 
        </p>
      </div>

      <button className='btn btn-default' onClick={onclick} disabled={disabled}>{btnText}</button>
    </div>
  )
}

export default PlanCard


PlanCard.propTypes = {
  title: PropTypes.any,
  amount: PropTypes.any,
  onclick: PropTypes.any,
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
}


  