import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Batch from "./auth/batch/reducer"
import admin from "./admin/reducer"
import Dashboard from "./dashboard/reducer";
import chat from "./chat/reducer"
import transactions from "./transactions/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  Batch,
  admin,
  chat,
  transactions
})

export default rootReducer
