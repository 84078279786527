/* GET Transactions */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const GET_PENDING_TRANSACTIONS = "GET_PENDING_TRANSACTIONS";
export const GET_PENDING_TRANSACTIONS_SUCCESS = "GET_PENDING_TRANSACTIONS_SUCCESS";

export const APPROVE_TRANSACTION = "APPROVE_TRANSACTION";
export const APPROVE_TRANSACTIONS_SUCCESS = "APPROVE_TRANSACTIONS_SUCCESS";
export const APPROVE_TRANSACTIONS_FAIL = "APPROVE_TRANSACTIONS_FAIL";



