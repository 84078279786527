import React, { useEffect, useState } from 'react'
import Iconfiy from 'pages/Utility/Iconfiy'
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card  } from 'reactstrap';
import Trans from "../../components/Common/TransactionCom"
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import NumberFormat from 'react-number-format';
const API_URL = process.env.REACT_APP_BASE_URL
const axiosApi = axios.create({
  baseURL: API_URL,
});



axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});



const PlanDetails = () => {
  const {id} = useParams();
  const [planDetails, setPlanDetails] = useState([])
  const [planTransactions, setPlanTransactions] = useState([])

  const getPlanDetails = async () => {
    await axiosApi.get(`${API_URL}/api/v1/user/plan/${id}`).then((res) => {
      setPlanDetails(res.data)
    })
  }

  const getPlanTransactions = async () => {
    await axiosApi.get(`${API_URL}/api/v1/user/plan/transactions/${id}`).then((res) => {
      setPlanTransactions(res.data)
    })
  }

  useEffect(() => {
    getPlanDetails()
    getPlanTransactions()
  }, [])

  return (
    <React.Fragment>
        <div className="page-content">
        <MetaTags>
          <title>Spoowalth plans | Save, Earn and Spend</title>
        </MetaTags>
      <Container>
        <Row>
          <Col md="10">
             <Row>
                <Col md="5">
               
                    <div className='earnings-detail d-flex flex-column text-center justify-content-center ' style={{ border: "1px solid white"}}>
                        <h5 style={{ marginBottom: '10px' }}>Daily Earnings</h5>
                        <h1 style={{ marginBottom: '12px' }}>₦{planDetails?.daily_earning}</h1>

                        <div className='d-flex flex-row justify-content-center'>
                          {/* <button><Iconfiy icon="bi:box-arrow-up" width="13px"/> top up</button>
                          <button><Iconfiy icon="formkit:help" width="13px"/>payout</button> */}
                        </div>
                      </div>
                 
                </Col>

                <Col md="6">
                   <Link to="/spoowealth">
                    <div className='close-button align-items-center' style={{ justifyContent: "flex-start", marginBottom: '20px' }}>
                      <Iconfiy icon="ep:arrow-left" width="15px"/> <h5 style={{ margin: '0px', marginLeft: '10px', fontSize: '20px' }}>Plan Details</h5>
                    </div>
                   </Link> 
                   

                    <div className='earnings-exp'>
                      <div>
                        <p className='light'>Status</p>
                        <p>{planDetails?.status}</p>
                      </div>

                      <div>
                        <p className='light'>Duration</p>
                        <p>{planDetails?.duration}</p>
                      </div>

                      <div>
                        <p className='light'>Total ROI</p>
                        <p>
                        <NumberFormat
                          value={planDetails?.total_roi} 
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                          /> 
                         </p>
                      </div>

                      <div>
                        <p className='light'>Daily Earnings</p>
                        <p>
                        <NumberFormat
                          value={planDetails?.daily_earning} 
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                          /> 
                        </p>
                      </div>

                      <div>
                        <p className='light'>Next Payout Date</p>
                        <p>{planDetails?.next_withdrawal_date}</p>
                      </div>

                      <div>
                        <p className='light'>Weekly ROI</p>
                        <p>
                        <NumberFormat
                          value={planDetails?.weekly_earning} 
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                          /> 
                       </p>
                      </div>

                      <div>
                        <p className='light'>Due Date</p>
                        <p>{planDetails?.due_date}</p>
                      </div>

                      <div>
                        <p className='light'>Charges</p>
                        <p>
                        <NumberFormat
                          value={planDetails?.trading_fee} 
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                          /> 
                        </p>
                      </div>
                    </div>

                    <div className='earnings-balance d-flex flex-row justify-content-between'>
                      <p>Savings Amount</p>
                      <h1>
                      <NumberFormat
                          value={planDetails?.trading_capital} 
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                          /> 
                        
                      </h1>
                    </div>

                    <br />

                    <div className='plan-head'>
                      <h2 style={{ marginBottom: '20px' }}>Plan Transaction</h2>
                    </div>

                    <div className='transactions'>
                      {planTransactions.length > 0 ? (
                      <>
                       {planTransactions.map((plan) => (
                        <>
                           <Trans type={plan.type} status={plan.status} date={plan.createdAt} amount={plan.amount} />
                        </>
                       ))}
                      </>
                      ) : (<><p>These plan has no transaction record yet.</p></>)}
            
                    </div>

                
                </Col>
             </Row>
          </Col>
        </Row>
      </Container>
      </div>
    </React.Fragment>
  )
}

export default PlanDetails