import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from "react-meta-tags";
import axios from "axios";
import NumberFormat from 'react-number-format';
import { withRouter, Link, useHistory } from "react-router-dom";
import DashSide from '../Dashboard/DashSide'
import Loader from 'pages/Utility/Loader'
import Trans from "../../components/Common/TransactionCom"
import { slice } from 'lodash'


import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";




const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});



const PendingTransactions = ()  => {
  const [loading, setLoading] = useState(false);
  const [ transactions, setTransactions] = useState([])
  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [userBanks, setUserBanks] = useState([])
  const [balance, setBalance] = useState(0)
  const [isCompleted, setIsCompleted] = useState(false)
  const [index, setIndex] = useState(5)
  const initialTrans = slice(transactions, 0, index)
  const [modal, setModal] = useState(false)
  const [transaction, setTransaction] = useState()

  
  const toggle = () => {
    setModal(!modal)
  }



  const loadTransactions = async() => {
     await  axiosApi.get(`${API_URL}/api/v1/user/transactions`).then((response) => {
         setTransactions(response.data.data)
      }).catch((error) => {
      })
  }


const getUserWallet = async () => {
  await axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
     setBalance(res.data.balance)
   }).catch((error) => {
    console.log(error)
   })
 }
 
 const getUserBanks = async () => {
   await axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
     setUserBanks(res.data.data)
   }).catch((error) => {
 
   })
 }

 const loadMore = () => {
  setIndex(index + 5)
  if (index >= transactions.length) {
    setIsCompleted(true)
  } else {
    setIsCompleted(false)
  }
}

 
 useEffect(() => {
  getUserWallet()
  getUserBanks()
  loadTransactions()
}, [balance])

const transactionDetails = async (trans) => {
    setTransaction(trans)
    setModal(true)
}
 

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Transactions | Save, Earn and Spend at your finger tips.</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md="4">
            <DashSide balance={balance} userBanks={userBanks} />
            </Col>
            <Col md="6">
            <div className='plan-head'>
                        <h2 style={{ marginBottom: '20px' }}>Transactions</h2>
                     </div>

                     {transactions.length > 0 ? (
                        initialTrans.map((tran) => (
                          <>
                          <Trans type={tran.type} status={tran.status} date={tran.createdAt} amount={tran.amount} onclick={() => transactionDetails(tran)} />
                         </>
                        ))
                      
                     ) : (
                        <>
                          <p>You have no transaction at the moment..</p>
                        </>
                     )}
                     {transactions.length > 0 && (
                       <>
                       {isCompleted ? (
                        <p onClick={loadMore}> Thats all your transactions </p>
                      ) : (
                        <p onClick={loadMore} className="btn btn-danger">
                          Load More +
                        </p>
                      )}
                  
                    </> )}
            </Col>
          </Row>
        </Container>
      </div>

      <Modal   isOpen={modal} toggle={toggle}  size="md" centered={true} >
          <h4 style={{ textAlign: "center", paddingTop: "10px"}}>Transaction Details</h4>
           <ModalBody className="py-3 px-5">
            <Card style={{ padding: "10px"}}>
            {transaction?.type === "WITHDRAWAL" ? (
            <h4 style={{ textAlign: "center"}}>
                <NumberFormat
                      value={transaction.amount} 
                      className="foo"
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
               /> 
            </h4>
            ) : transaction?.type === "FUNDING" ? (
              <h4 style={{ textAlign: "center"}}>
              <NumberFormat
                    value={transaction.amount} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
             /> 
          </h4>
            ) : transaction?.type === "SPOOWEALTH" ? (<h4>- ₦{transaction?.amount}</h4>) : <h4>₦{transaction?.amount}</h4>}
            <p style={transaction?.status === "SUCCESS" ? {color: "green", textAlign: "center"} : transaction?.status === "PENDING" ? {color: "orange", textAlign: "center"} : null}>{transaction?.status === "SUCCESS" ? "Succesfull" : transaction?.status === "PENDING" ? "Pending" : null}</p>
            <div>
              <span>Amount</span>
              <span style={{ float: "right"}}>
              <NumberFormat
                    value={transaction?.amount} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
             /> 
              </span> 
              <br />

              <span>Transaction Type</span>
              <span style={{ float: "right"}}>{transaction?.type}</span> <br />

      
              <span>Wallet From</span>
              <span style={{ float: "right"}}>{transaction?.wallet_type}</span> <br />

              <span>Old Balance</span>
              <span style={{ float: "right"}}>
              <NumberFormat
                    value={transaction?.old_balance} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
             /> 
              </span> <br />

              <span>New Balance</span>
              <span style={{ float: "right"}}>
              <NumberFormat
                    value={transaction?.new_balance} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
             /> 
              </span> <br />


              <span>Payment Method</span> 
              <span style={{ float: "right"}}>Balance</span> <br />
              
            </div>
            </Card>

            <Card style={{ border: "1px solid gray", padding: "5px"}}>
              <span>Narration</span>
              <p>{transaction?.narration}</p>
            </Card>
            </ModalBody>
     </Modal>
    </React.Fragment>
  );
};

PendingTransactions.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  show: PropTypes.any,
  loading: PropTypes.bool,
}

export default withRouter(PendingTransactions);