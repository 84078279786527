import React from 'react'
import PropTypes from 'prop-types';




const Bankcom = ({account_name, account_number, bank_name, onSelect, isActive, id}) => {
  return (
  
    <div style={isActive === id ? {border: '1px solid orange'} : null}  className='temp-contain d-flex flex-row align-items-center' onClick={onSelect}>
      <div className='spooIcon'>
       
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{account_name}</h4>
        <p>{account_number}</p>
      </div>

      <div>
         <p>{bank_name}</p>
      </div>
    </div>
  )
}

export default Bankcom


Bankcom.propTypes = {
    account_name: PropTypes.any,
    account_number: PropTypes.any,
    bank_name: PropTypes.any,
    onSelect: PropTypes.any,
    isActive: PropTypes.any,
    id: PropTypes.any
}


  