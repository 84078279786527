import React, { useState, useEffect } from 'react'
import Iconfiy from 'pages/Utility/Iconfiy'
import axios from "axios";
import PlanCom from '../../components/Common/PlanCom'
import { Col, Container, Row, Card, Modal, ModalBody,ModalHeader,Alert } from 'reactstrap'
import MetaTags from "react-meta-tags";
import Trans from "../../components/Common/TransactionCom"
import Savings from "../../components/Common/Savings"
import DashSide from '../Dashboard/DashSide'
import Loader from 'pages/Utility/Loader'
import {toast} from "react-toastify"
import BackComponent from "../../components/Common/BackComponent"

const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});


const Plans = () => {
const [show, setShow] = useState(false)
const [userBanks, setUserBanks] = useState([])
const [balance, setBalance] = useState(0)
const [errorMessage, setErrorMessage] = useState("")
const [succcessMessage, setSucessMessage] = useState("")
const [userPlans, setUserPlans] = useState([])
const [planTransactions, setPlanTransactions] = useState([])
const [isLoading, setIsLoading] = useState(false)
const [formData, setFormData] = useState({ trading_capital: "", plan_type: ""})
const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))


const onCloseClick = () => {

}


const getUserWallet = async () => {
 await axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
    setBalance(res.data.balance)
  }).catch((error) => {
   console.log(error)
  })
}

const getUserBanks = async () => {
  await axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
    setUserBanks(res.data.data)
  }).catch((error) => {

  })
}

const getUserPlans = async () => {
  await axiosApi.get(`${API_URL}/api/v1/user/user-plans`).then((res) => {
    setUserPlans(res.data)
  }).catch((error) => {

  })
}

const getPlanTransactions = async () => {
  await axiosApi.get(`${API_URL}/api/v1/user/spoowealth-transactions`).then((res) => {
    setPlanTransactions(res.data)
  }).catch((error) => {

  })
}

const savePlan = async () => {
   setIsLoading(true)
   let data = {
     trading_capital: formData.trading_capital,
     plan_type: formData.plan_type
   }
   if(formData.plan_type === "STARTER_PACK" && formData.trading_capital > 240000) {
    setErrorMessage("Maximum amount for starter pack is ₦240,000")
    setIsLoading(false)
    return;
   }
   if(formData.plan_type === "STARTER_PACK" && formData.trading_capital < 5000) {
    setErrorMessage("Minimum amount for starter pack is ₦5,000")
    setIsLoading(false)
    return;
   }

   if(formData.plan_type === "PREMIUM_PAcK" && formData.trading_capital > 240000) {
    setErrorMessage("Maximum amount for premium pack is ₦240,000")
    setIsLoading(false)
    return;
   }
   if(formData.plan_type === "PREMIUM_PAcK" && formData.trading_capital < 250000) {
    setErrorMessage("Minimum amount for premium pack is ₦250,000")
    setIsLoading(false)
    return;
   }

   if(formData.plan_type === "ULTIMATE_PACK" && formData.trading_capital > 1000000) {
    setErrorMessage("Maximum amount for ultimate pack is ₦1,000,000")
    setIsLoading(false)
    return;
   }
   if(formData.plan_type === "ULTIMATE_PACK" && formData.trading_capital < 500000) {
    setErrorMessage("Minimum amount for ultimate pack is ₦500,000")
    setIsLoading(false)
    return;
   }
   await axiosApi.post(`${API_URL}/api/v1/user/create-trading-plan`, data).then((res) => {
     setIsLoading(false)
     setShow(false)
     // load plans
     getUserPlans()
     getUserWallet()
     getPlanTransactions()
     toast.success('🦄 Congratulations!, Your plan has been created successfully', {
      position: "bottom-left",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });


   }).catch((err) => {
    setIsLoading(false)
    setErrorMessage(err.response.message)
   })
}


const toggle = () => {
  setShow(!show)
}

useEffect(() => {
  getUserWallet()
  getUserBanks()
  getUserPlans()
  getPlanTransactions()
}, [balance])



  return (
    <React.Fragment>
       <div className="page-content">
        <MetaTags>
          <title>Spoowalth plans | Save, Earn and Spend</title>
        </MetaTags>
       <Container fluid>
          <Row>
            <Col md="10">
               <Row>
                  <Col md="5">
                  <Row>
                    <Col md="12">
                        <DashSide balance={balance} userBanks={userBanks} />
                    </Col>

                    <Col md="12" className='savings' style={{ backgroundColor: "white", paddingTop: "20px"}}>
                      <Savings id={1} title="Spoowealth" details="Earn 30% interest per month" link="/spoowealth"  />
                      <Savings id={2} title="Spoosafe" details="Fixed deposit with 5% per month" isActive={true} link="/spoosafe"  />
                      <Savings id={3} title="SpooFlex" details="Save and withdraw anytime 2.5%" link="/spooflex" />
                      {/* <Savings id={4} title="SpooCircle" details="Save with your friends to reach a goal"  link="/spoocircle" /> */}
                     <hr />

                    </Col>
                 </Row>
                  </Col>

                  <Col md="6">
                          <Row>
                             <Col md="8">
                              <BackComponent title="Spoosafe" device="" link="/savings" />
                             </Col>
                             <Col md="4">
                               <button disabled onClick={() => setShow(true)} style={{ alignSelf: "flex-end"}} className='btn btn-primary'><Iconfiy icon="ic:round-plus" width="12px"/>Create Plan</button>
                             </Col>
                          </Row>

                        <div style={{ marginTop: "20px"}}>
                           <p>Hi {user.data.first_name}, We are currently working on these plan type, and we will be more than happy to launch it out for you as soon as possible. &#128522;.</p>
                           {/* {userPlans.length > 0 ? (
                              <>
                                {userPlans.map((plan) => (
                                  <>
                                   <PlanCom startAmount={plan.trading_capital} status={plan.status} duration={`${plan.duration}`} link={`/plan-details/${plan.id}`} />
                                  </>
                                ))}
                              </>
                            ) : (<><p>You have no spoowealth plan at this moment!</p></>)} */}
                          
                        
                        </div>

               

                      <br />

                      {/* <div className='plan-head'>
                        <h2 style={{ marginBottom: '20px' }}>Transactions</h2>
                      </div>

                      <div className='transactions'>
                        {planTransactions.length > 0 ? (
                          <>
                            {planTransactions.map((tran) => (
                              <>
                                 <Trans type={tran.type} status={tran.status} date={tran.createdAt} amount={tran.amount} />
                              </>
                            ))}
                          </>
                        ) : (<><p>No transactions for Spoowalth</p></>)}
                      </div>
               */}
                  </Col>
               </Row>
            </Col>
          </Row>
       </Container>
     </div>

     <Modal   isOpen={show} toggle={toggle}  size="md" centered={true} >
          {isLoading && <Loader />}
           <ModalBody className="py-3 px-5">
            <h4 className="pt-5">Create Spoowealth plan</h4>
            <p>Earn 20% - 30% interest per month</p>

            {errorMessage && (<Alert color="danger">{errorMessage}</Alert>)}

            <div className='input-set'>
                    <input type='number' name="trading_capital" onChange={(e) => setFormData({ ...formData, trading_capital: e.target.value})} className='form-control mine-input' placeholder='Enter plan amount'/>
                    <span>Balance:</span> <span style={{ color: "green"}}>₦{balance}</span>
                  </div>
                  <br />
                 
                 <div className='input-set'>
                   <span>Select plan type</span>
                  <select className='form-control' name="plan_type" onChange={(e) => setFormData({...formData, plan_type: e.target.value})}  style={{ marginTop: "10px"}}>
                    <option>Select Type</option>
                    <option value="STARTER_PACK">Starter(1 month)</option>
                    <option value="PREMIUM_PACK">Premium(2 Months)</option>
                    <option value="ULTIMATE_PACK">Ultimate(3 Months)</option>
                  </select>
                 </div>

                 <br />
               
            
          <button
            onClick={(e) => {savePlan()}}
            className="btn btn-primary"
            style={{ width: '100%'}}
            >
            Create Plan
            </button>
          
      </ModalBody>
    </Modal>
    </React.Fragment>
  )
}

export default Plans