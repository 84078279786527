import React, {useEffect, useState} from 'react'
import Iconfiy from "pages/Utility/Iconfiy"
import axios from "axios";
import Loader from 'pages/Utility/Loader'
import PropTypes from "prop-types";
import {v4 as uuidv4} from 'uuid';
import { Col, Modal, ModalBody, Row, Table, Alert, Card } from "reactstrap"
import Bankcom from 'components/Common/BankCom';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';

import AuthCode from "react-auth-code-input"
const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});

const DashSide =  ({ balance, userBanks}) => {

  const [showBalance, setShowBalance] = React.useState(false);
  const [modal, setModal] = useState(false)
  const [isActive, setIsActive] = useState("")
  const [topUpModal, setTopUpModal] = useState(false)
  const [topUpAmount, setTopUpAmount] = useState(0)
  const [destinationAccount, setDestinationAccount] = useState("")
  const [amount, setAmount] = useState(parseInt(0))
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [disabled, setDisabled] = useState(false)
  const [pinVerified, setPinVerified] = useState(false)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pin, setPin] = useState("")
  const [pinError, setPinError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [withdrawalSuccessMessage, setWithdrawalSuccessMessage] = useState("")
  const [withdrawalErrorMessage, setWithdrawalError] = useState("")



  const toggleBalanceVisibility = () => {
    setShowBalance((prevShowBalance) => !prevShowBalance);
  };
 



  const withdraw = async () => {
      setModal(true)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const toggleTop = () => {
    setTopUpModal(!topUpModal)
  }

  const handleChange = (code) => {
      setCount(code.length)
      setPin(code)
  }

  const selectBank = (bank) => {
     setIsActive(bank.id)
     setDestinationAccount(bank.id)
  }



  const  makePayment = () => {
    FlutterwaveCheckout({
      public_key: `${process.env.REACT_APP_FLWPUB}`,
      tx_ref: `SpooPay-${uuidv4()}`,
      amount: `${topUpAmount}`,
      currency: "NGN",
      payment_options: "card, mobilemoneyghana, ussd",
      callback: function(payment) {
        // Send AJAX verification request to backend
        verifyTransactionOnBackend(payment.transaction_id);
      },
      onclose: function(incomplete) {
        if (incomplete || window.verified === false) {
          document.querySelector("#payment-failed").style.display = 'block';
        } else {
          document.querySelector("form").style.display = 'none';
          if (window.verified == true) {
            document.querySelector("#payment-success").style.display = 'block';
          } else {
            document.querySelector("#payment-pending").style.display = 'block';
          }
        }
      },

      customer: {
        email: user.data.email,
        phone_number: user.data.phone_number,
        name: user.data.name,
      },
      customizations: {
        title: "SpooPay",
        description: "Top up SpooPay Wallet",
        logo: "https://edgeequitys.s3.amazonaws.com/Artboard+1+copy+4.png",
      },
    });
  }

  const verifyTransactionOnBackend = (transactionId) => {
    let data = {
       transaction_id: transactionId,
       expectedAmount: topUpAmount,
       expectedCurrency:  "NGN"
    }
    axiosApi.post(`${API_URL}/api/v1/user/topup`, data).then((res) => {
      window.location.reload();
      toast.success('🦄 Wow!, Your top up was sucessfull', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }).catch(() => {

    })
  }

  const verifyPin = async () => {
      setLoading(true)
      let data = {
        pin: pin,
        device_token: "1234"
      }
      axiosApi.post(`${API_URL}/api/v1/user/pin/verify`, data).then((res) => {
        setLoading(false)
        setPinVerified(true)
      }).catch((error) => {
        setLoading(false)
        console.log(error.response.data.message)
        setPinError(error.response.data.message)
      })
  }



  useEffect(() => {
    if(count > 3){
      verifyPin()
    }

  }, [count])

  const sendWithdrawalrequest = () => {
    setIsLoading(true)

    let payload = {
      destination_account: destinationAccount,
     amount,
     pin
    }
   
    axiosApi.post(`${API_URL}/api/v1/user/wallet/withdraw`, payload).then((res) => {
         setIsLoading(false);
          window.location.reload(false);
          toast.success('🦄 Congratulations!, Your withdrawal is successfully', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }).catch((error) => {
         setIsLoading(false);
         setWithdrawalError(error.response.message)
    })
  }





  return (
    <>
     <Card style={{ borderRadius: "10px", backgroundColor: "#fff"}}>
      <div className="text-center" style={{ paddingTop: "30px"}}>
        <h5 style={{ color: "#ffff"}}>Balance</h5>
        <div className='d-flex flex-row justify-content-center align-items-center'>
          <h2 style={{ color: "#6210CC", fontWeight: "bolder"}}>
             {
              showBalance ? (
              <>
              <NumberFormat
                      value={balance} 
                      className="foo"
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
               /> 
              </>) : (
              <>
               <span>₦{"*".repeat(balance.length - 3) + "." + '*'.repeat(2)}</span>
              </>)
             }
          </h2>
          <div style={{ marginLeft: '7px',cursor: 'pointer' }} onClick={toggleBalanceVisibility}>
            <Iconfiy icon={showBalance ? "ion:eye-outline" : "ion:eye-off-outline"}/>
          </div>
        </div>
      </div>
    
      

      <div style={{ paddingBottom: "10px", margin: "auto", marginTop: "15px"}}>
        <button onClick={() => { withdraw()}} className='btn btn-default temp-contain' style={{ border: "1px solid gray", backgroundColor: "#6210CC", color: "white"}}><Iconfiy icon="solar:arrow-up-outline" angle="225" width="20px"/> Withdraw</button>
        <button onClick={() => setTopUpModal(true)} className='btn btn-default temp-contain' style={{ border: "1px solid gray", marginLeft: "10px"}}><Iconfiy icon="ic:round-plus" width="13px"/> Top up</button>
      </div>

      
     </Card>
      

      <Modal  
         isOpen={modal}
        toggle={toggle} size="md" centered={true}>
        <ModalBody className="py-3 px-5">

                  <h4 className="pt-5">Withdraw</h4>
                  <p>Please enter your withdrawal details</p>
                   {pinError ?  <Alert color="danger">{pinError}</Alert>  : null}
                        
  
                  <div className='input-set'>
                    <input type='number' onChange={(e) => setAmount(e.target.value)} className='form-control mine-input' placeholder='Enter amount'/>
                    <span>Balance:</span> <span style={{ color: "green"}}>₦{balance}</span>
                  </div>
                  <br />
                  <p>Select your bank</p>
                  {userBanks.map((bank) => (
                    <>
                      <Bankcom account_name={bank.account_name} account_number={bank.account_number} id={bank.id} bank_name={bank.bank_name} onSelect={() => {selectBank(bank)}} isActive={isActive}  />
                    </>
                  ))}
                 
                   <hr />
                   <p>Enter Your Transaction Pin</p>
                   <AuthCode
                        characters={4}
                        onKeyUp={() => setCount(count + 1)}
                        className="form-control form-control-lg text-center"
                        inputStyle={{
                          width: "50px",
                          height: "calc(1.5em + 1rem + 2px)",
                          padding: ".5rem 1rem",
                          borderRadius: "8px",
                          fontSize: "1.01562rem",
                          textAlign: "center",
                          marginRight: "15px",
                          border: "1px solid #ced4da",
                          textTransform: "uppercase",
                          borderRadius: ".4rem"
                        }}
                        onChange={handleChange}
                      />
                      <span>Transaction Pin</span> {loading && (<><span style={{ color: "green", float: "right"}}>Verifying Pin...</span></>)} {pinVerified && !loading && (<><span style={{ color: "green", float: "right"}}>Verified</span></>)} {!loading && !pinVerified && (<><span style={{ color: "red", float: "right"}}>Unverified Pin</span></>)}
  
                  <button onClick={() => {sendWithdrawalrequest()}} className='btn btn-primary full mt-5' disabled={disabled}>{isLoading && (<Loader />)} {!isLoading && "Withdraw" }</button>
          
        </ModalBody>
    </Modal>

    <Modal  
         isOpen={topUpModal}
        toggle={toggleTop} size="md" centered={true}>
          <ModalBody className="py-3 px-5">

                  <h4 className="pt-5">Top Up Your Wallet</h4>

                        
  
                  <div className='input-set'>
                    <input type='number' className='form-control mine-input' onChange={(e) => setTopUpAmount(e.target.value)} placeholder='Enter amount'/>
          
                    <span>Balance:</span> <span style={{ color: "green"}}>₦{balance}</span>

                  </div>
                  <br />
                 
                  <button className='btn btn-primary full mt-5' onClick={() => {makePayment()}}>Top Up</button>
          
        </ModalBody>
    </Modal>
    </>
  )
}

export default DashSide


DashSide.propTypes = {
  balance: PropTypes.any,
  userBanks: PropTypes.any,
}

