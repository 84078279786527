import React, {useState, useEffect} from 'react'
import { MetaTags } from 'react-meta-tags'
import Iconfiy from '../Utility/Iconfiy'
import { Container, Alert, Input } from 'reactstrap'
import axios from "axios";
import Loader from 'pages/Utility/Loader'
const API_URL = process.env.REACT_APP_BASE_URL;
import PropTypes from "prop-types";
import Iconify from '../Utility/Iconfiy'
import AuthCode from "react-auth-code-input"
import {toast} from "react-toastify"



const axiosApi = axios.create({
  baseURL: API_URL,
});




const UserForgetPassword = props => {
  const [errors, setErrors] = useState([])
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [viewPass, setViewPass] = React.useState(true);
  const [code, setCode] = useState("")

  const handlePassView = (e) => {
    e.preventDefault();
    setViewPass(!viewPass);
  }

  const changePass = async () => {
    setLoading(true)
    let data = {
      email: email,
      otp: code,
      password: password
    }
    axiosApi.post(`${API_URL}/api/v1/user/password/reset`, data).then((res) => {
    setLoading(false)
    toast.success('🦄 Congratulations!, Your password has changed successfully', {
      position: "bottom-left",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
     props.history.push("/login");
    }).catch((error) => {
      setLoading(false);
      if(error.response.data.status === false){
        setError(error.response.data.message)
      }else{
        setErrors(error.response.data.error)
      }
    })
  }

  const handleChange = (code) => {
    setCode(code)
}

useEffect(() => {
  if (localStorage.getItem("token")) {
      const obj = JSON.parse(localStorage.getItem("token"))
      setEmail(obj.email)
  
  }
}, [])



  return (
    <React.Fragment>
      <div className='all-container'>
        <MetaTags>
          <title>Password Recovery | Save, Earn & Spend At Your Finger Tips</title>
        </MetaTags>

       

        <Container>
        {loading && <Loader/>}
          <div className='container-fluid d-flex justify-content-center'>
            <div className='major-width' style={{ paddingBottom: "30px", border: "1px solid gray" }}>
                  <>
                    <a href='/forgot-password'>
                    <div className='close-button' style={{ justifyContent: "flex-start", marginBottom: '30px' }}>
                      <Iconfiy icon="ep:arrow-left" width="15px"/>
                    </div>
                    </a>
                   

                    <div className='text-left migi'>
                      <h2>Create new password</h2>
                      {errors?.length > 0 ? 
                        errors.map((err) => (
                          <>
                            <Alert color="danger">{err.message}</Alert> 
                          </>
                        ))
                        : 
                        null}
                        {error ? <Alert color="danger">{error}</Alert>  : null}
                    </div>

                    <div className='input-set'>
                      <label className='form-label'>Enter code sent to your number</label>
                      <AuthCode
                        characters={4}
                        className="form-control form-control-lg text-center"
                        inputStyle={{
                          width: "50px",
                          height: "calc(1.5em + 1rem + 2px)",
                          padding: ".5rem 1rem",
                          borderRadius: "8px",
                          fontSize: "1.01562rem",
                          textAlign: "center",
                          marginRight: "15px",
                          border: "1px solid #ced4da",
                          textTransform: "uppercase",
                          borderRadius: ".4rem"
                        }}
                        onChange={handleChange}
                      />
                    </div>

                    <label className='form-label '>Password</label>
                    <div className='passwordIput-container'>
                    <Input
                        name="password"
                        type={viewPass ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        
                       
                      />
                      
                      <div className='nigga' onClick={handlePassView}>
                        <Iconify icon={viewPass ? "ion:eye-outline" : "ion:eye-off-outline"}/>
                      </div>
                    </div>

                    <button className='btn btn-primary full mt-5' onClick={() => {changePass() }}>Change Password</button>
                  </> 
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserForgetPassword

UserForgetPassword.propTypes = {
  history: PropTypes.object,
};


// ep:arrow-left
